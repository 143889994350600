
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "sass:map";
@use 'sass:list';
@use 'sass:math';
@use 'sass:meta';
@use '@angular/material' as mat;
@include mat.core();
@import 'src/app/global.styles.scss';
// @import '@angular/material/theming';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


$custom-palette-3: (
  50: #fbf5e0,
   100: #f5e6b1,
   200: #efd67f,
   300: #e8c74e,
   400: #e5ba2b,
   500: #e1af15,
   600: #e1a30e,
   700: #e0920a,
   800: #df8206,
   900: #dc6603,
    A100: #e1a30e,
    A200: #e0920a,
    A400: #df8206,
    A700: #dc6603,
  contrast: (
    50: #e8ebfb,
    100: #c4cdf6,
    200: #9cadf0,
    300: #708ceb,
    400: #4a71e6,
    500:#0a57e0,
    600: #004fd5,
    700: #0044c8,
    800: #003abd,
    900: #0025ab,
     A100: #8c9eff,
     A200: #536dfe,
     A400: #3d5afe,
     A700: #304ffe,
  )
);
$custom-palette: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: #3f51b5,
  600: #3949ab,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: white,
    100: white,
    200: white,
    300:white,
    400:white,
    500:white,
    600:white,
    700:white,
    800:white,
    900:white,
    A100: white,
    A200:white,
    A400:white,
    A700:white,
  )
);
$custom-palette-2: (
  50: yellow,
  100: yellow,
  200: yellow,
  300: yellow,
  400: yellow,
  500: yellow,
  600: yellow,
  700: yellow,
  800: yellow,
  900: yellow,
  A100: yellow,
  A200: yellow,
  A400: yellow,
  A700: yellow,
  contrast: (
    50: white,
    100: white,
    200: white,
    300:white,
    400:white,
    500:white,
    600:white,
    700:white,
    800:white,
    900:white,
    A100: white,
    A200:white,
    A400:white,
    A700:white,
  )
);
// $proposal-ingestion-tool-primary: mat.define-palette($custom-palette);
$proposal-ingestion-tool-primary: mat.m2-define-palette($custom-palette);

$proposal-ingestion-tool-accent: mat.m2-define-palette($custom-palette-3, A200, A100, A400);



// The warn palette is optional (defaults to red).
$proposal-ingestion-tool-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$proposal-ingestion-tool-theme: mat.m2-define-light-theme((
  color: (
    primary: 	$proposal-ingestion-tool-primary,
    accent: $proposal-ingestion-tool-accent,
    warn: $proposal-ingestion-tool-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($proposal-ingestion-tool-theme);


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// @import "tailwindcss/base";
// @import "tailwindcss/components";
// @import "tailwindcss/utilities";
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!


/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.full-height{
  height: 100%;
}
.app-doc{
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

